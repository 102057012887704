import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Home from "./components/Home";
import TagsCloud from "./components/TagsCloud";
import SuccessRates from "./components/SuccessRates";
import SupplyVsDemand from "./components/SupplyVsDemand";
import SupplyVsDemandTech from "./components/SupplyVsDemandTech";
import ActiveCompanies from "./components/ActiveCompanies";
import ResponsiveCompanies from "./components/ResponsiveCompanies";
import HttpsRedirect from "react-https-redirect";
import OfferedPerks from "./components/OfferedPerks";
import CallToAction from "./components/CallToAction";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KBFZH82'
}

TagManager.initialize(tagManagerArgs)

export default function App() {
  return (
    <HttpsRedirect>
      <Router>
        <div className="bg">
          <div className="bgimage" style={{ backgroundImage: "url('/bg.svg')" }}>
            <Header />
            <div className="gb-container mt2">
              <CallToAction/>
              <Switch>
                <Route path="/reports/tags-cloud">
                  <TagsCloud />
                </Route>
                <Route path="/reports/success-rates-salaries">
                  <SuccessRates />
                </Route>
                <Route path="/reports/supply-vs-demand">
                  <SupplyVsDemand />
                </Route>
                <Route path="/reports/supply-vs-demand-tech">
                  <SupplyVsDemandTech />
                </Route>
                <Route path="/reports/most-active-companies">
                  <ActiveCompanies />
                </Route>
                <Route path="/reports/responsive-companies">
                  <ResponsiveCompanies />
                </Route>
                <Route path="/reports/popular-perks">
                  <OfferedPerks />
                </Route>
                <Route path="/">
                  <div
                    className="homebg"
                  />
                  <Home />
                </Route>
              </Switch>
              <div className="text-center p2 border-top border-sherpa-blue-2 mt3 gray">
                All content and visualizations shown are licensed under{" "}
                <a
                  href="https://creativecommons.org/licenses/by-sa/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Creative Commons BY-SA 4.0.
                </a>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </HttpsRedirect>
  );
}
