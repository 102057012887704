import React from "react";

import groupBy from "lodash/fp/groupBy";
import keys from "lodash/fp/keys";
import find from "lodash/fp/find";

import { gobColorScheme, gobTheme, tagsGroups } from "../utils";
import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import { ResponsiveAreaBump } from "@nivo/bump";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";


const beginningYear = 2020;

const SupplyVsDemandTech = () => {
  const jobsTechs = useInsights("offered_technologies_across_time", {
    year: beginningYear,
  });
  const appsTechs = useInsights("demanded_technologies_across_time", {
    year: beginningYear,
  });

  const Chart = ({ data }) => (
    <div style={{ height: 450 }}>
      <ResponsiveAreaBump
        data={data}
        margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
        spacing={8}
        colors={gobColorScheme}
        theme={gobTheme}
        blendMode="lighten"
        startLabel="percentage"
        startLabelTextColor="#fff"
        startLabelPadding={5}
        endLabelPadding={5}
        endLabelTextColor="#fff"
        axisTop={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -36,
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        tooltip={({ serie }) => {
          return (
            <div className="bg-sherpa-blue border-radius p-1 box-shadow">
              <div>
                <p className="w700">
                  <span className="size1 mr-3" style={{ color: serie.color }}>
                    ●
                  </span>
                  {serie.id}
                </p>
              </div>
              {serie.data.map((d) => (
                <div key={d.x}>
                  <strong className="bold">{d.x}: </strong>
                  {d.y} %
                </div>
              ))}
            </div>
          );
        }}
      />
    </div>
  );

  const VisualizeTechsGroup = ({ title, group, children }) => {
    if (!group) return null;

    const dataPerTag = groupBy("label")(group.data);
    const nivoData = keys(dataPerTag).map((k) => ({
      id: k,
      data: dataPerTag[k].map((d) => ({
        x: d.year,
        y: d.percentage,
      })),
    }));
    return (
      <div className="col-md-6" key={group.group}>
        <h3 className="h3 mt1 text-center w700">{title || group.group}</h3>
        <Chart data={nivoData}></Chart>
        {children}
      </div>
    );
  };

  const VisualizeChart = ({ title, data, children, id }) => {
    if (!data) return null;

    const groupedData = groupBy("label")(data);
    const nivoData = keys(groupedData).map((k) => ({
      id: k,
      data: groupedData[k].map((d) => ({
        x: d.year,
        y: d.percentage,
      })),
    }));
    return (
      <div className="col-md-6" key={id}>
        <h3 className="h3 mt1 text-center w700">{title}</h3>
        <Chart data={nivoData}></Chart>
        {children}
      </div>
    );
  };

  const Header = () => {
    return (
      <ReportHeader
        epigraph="Supply v/s demand"
        headline="How jobs and applications are matching?"
        icon="transactions"
      >
        <p className="size1">
          These charts compare how demand (jobs offered, left column) and supply (applications sent, right column)
          are matching in Get on Board, broken down by different groups of technologies and frameworks.
          Comparing left and right charts help detect gaps in talent supply and demand.
        </p>
        <div className="mt2 text-center">
          <a href="#backend" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Programming languages</a>
          <a href="#server-side-frameworks" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Server-side frameworks</a>
          <a href="#front-end-frameworks" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Front-end frameworks</a>
          <a href="#mobile" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Mobile technologies</a>
          <a href="#digital-marketing" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Digital Marketing</a>
          <a href="#data-science" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Data Science</a>
          <a href="#data-visualization" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Data Visualization</a>
          <a href="#ai-technologies" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">AI technologies</a>
          <a href="#cms" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">CMS</a>
          <a href="#design-tools" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Design tools</a>
          <a href="#databases" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Database technologies</a>
          <a href="#cloud" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Cloud platforms</a>
        </div>
      </ReportHeader>
    );
  };

  if (
    !jobsTechs || !appsTechs
  )
    return (
      <div>
        <Header />
        <Loading />
        <InsightsProBanner
        title="Tómale el pulso al mercado"
        copy="Insights Pro te entrega aún más detalles sobre cómo se comporta el mercado del talento tech en LATAM."
        cta="Conoce más de Insights Pro"
        tag="supply-vs-demand"
      />
      </div>
    );

  return (
    <div>
      <Header />

      {/* Backend langs */}
      <div className="mb5" id="backend"></div>
      <h2 className="h2 w900 text-center mt1">Programming languages</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.backendLangs,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.backendLangs,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* Server side fw */}
      <div className="mb5" id="server-side-frameworks"></div>
      <h2 className="h2 w900 text-center mt1">Server-side frameworks</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.serverSideFrameworks,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.serverSideFrameworks,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* angular vs react vs vue... */}
      <div className="mb5" id="front-end-frameworks"></div>
      <h2 className="h2 w900 text-center mt1">Front-end frameworks</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.angularReactVueEmber,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.angularReactVueEmber,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* mobile techs */}
      <div className="mb5" id="mobile"></div>
      <h2 className="h2 w900 text-center mt1">Mobile technologies</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.mobile,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.mobile,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* digital marketing */}
      <div className="mb5" id="digital-marketing"></div>
      <h2 className="h2 w900 text-center mt1">Digital Marketing</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.digitalMarketing,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.digitalMarketing,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* data science */}
      <div className="mb5" id="data-science"></div>
      <h2 className="h2 w900 text-center mt1">Data Science</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.dataScience,
          })(jobsTechs)}
          title="Jobs posted"
        /> 
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.dataScience,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* data visualization */}
      <div className="mb5" id="data-visualization"></div>
      <h2 className="h2 w900 text-center mt1">Data Visualization</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.dataVisualization,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.dataVisualization,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* ai technologies */}
      <div className="mb5" id="ai-technologies"></div>
      <h2 className="h2 w900 text-center mt1">AI technologies</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.aiTechnologies,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.aiTechnologies,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* cms */}
      <div className="mb5" id="cms"></div>
      <h2 className="h2 w900 text-center mt1">CMS</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.cms,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.cms,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* design tools */}
      <div className="mb5" id="design-tools"></div>
      <h2 className="h2 w900 text-center mt1">Design tools</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.designTools,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.designTools,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* databases */}
      <div className="mb5" id="databases"></div>
      <h2 className="h2 w900 text-center mt1">Database technologies</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.databases,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.databases,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

      {/* cloud */}
      <div className="mb5" id="cloud"></div>
      <h2 className="h2 w900 text-center mt1">Cloud platforms</h2>
      <div className="row pb3">
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.cloud,
          })(jobsTechs)}
          title="Jobs posted"
        />
        <VisualizeTechsGroup
          group={find({
            group: tagsGroups.cloud,
          })(appsTechs)}
          title="Applications sent"
        />
      </div>

    </div>
  );
};

export default SupplyVsDemandTech;
