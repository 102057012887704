const MONEY_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const COLOR_SCHEME = [
  "#ff7777",
  "#0093d3",
  "#00c4f5",
  "#47c27a",
  "#933db8",
  "#f39c12",
  "#f4cc00",
  "#6bdaf5",
  "#bf5050",
];

const MONO_COLOR_SCHEME = ["#f2f6f7", "#ecf1f4", "#d9e3e8"];
const BLUE_MONO_COLOR_SCHEME = ["#0f3942", "#00c4f5", "#0093d3", "#6bdaf5"];
const DARK_COLOR_SCHEME = [
  "#0f3942",
  "#ff7777",
  "#0093d3",
  "#00c4f5",
  "#47c27a",
  "#933db8",
  "#f39c12",
  "#f4cc00",
  "#6bdaf5",
  "#bf5050",
];

export const gobTheme = {
  background: "rgba(15, 57, 66, 0.6)",
  textColor: "#fff",
  fontSize: 13,
  axis: {
    domain: {
      line: {
        stroke: "#a7b7be",
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: "#a7b7be",
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      stroke: "#002e39",
      strokeWidth: 1,
    },
  },
  tooltip: {
    container: {
      background: "#005267",
      borderRadius: 4,
    },
  },
};

/*
  The names of the group should be synced
  with the backend serving insights
*/
export const tagsGroups = {
  frontEndBackEndFullStack: "Back-end vs Front-end vs Full-stack",
  backendLangs: "Back-end languages",
  serverSideFrameworks: "Server side frameworks",
  frontEndStack: "Front-end stack",
  angularReactVueEmber: "Angular vs React vs Vue vs Ember",
  mobile: "Mobile development",
  digitalMarketing: "Digital Marketing",
  dataVisualization: "Data Visualization",
  llms: "LLMs",
  aiTechnologies: "AI technologies",
  cms: "CMS",
  designTools: "Design Tools",
  databases: "Databases",
  dataScience: "Data Science",
  restVsGraphql: "Rest API vs GraphQL",
  cloud: "Cloud solutions",
  virtualization: "Virtualization",
};

export const formatMoney = (value) => MONEY_FORMATTER.format(value);
export const gobColorScheme = COLOR_SCHEME;
export const monoColorScheme = MONO_COLOR_SCHEME;
export const blueMonoColorScheme = BLUE_MONO_COLOR_SCHEME;
export const darkColorScheme = DARK_COLOR_SCHEME;
export const apiVersion = "0";
export const apiUrl = `${
  process.env.NODE_ENV === "development"
    ? "http://dev.getonbrd.com:3000"
    : "https://www.getonbrd.com"
}/api/v${apiVersion}/insights`;
