import React from "react";

import groupBy from "lodash/fp/groupBy";
import keys from "lodash/fp/keys";
import find from "lodash/fp/find";

import { gobColorScheme, gobTheme, tagsGroups } from "../utils";
import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import { ResponsiveAreaBump } from "@nivo/bump";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";


const beginningYear = 2020;

const SupplyVsDemand = () => {

  const remoteModalityDemand = useInsights(
    "offered_jobs_by_remote_modality",
    { year: beginningYear }
  );
  const remoteModalitySupply = useInsights(
    "talent_supply_by_remote_modality",
    { year: beginningYear }
  );

  const seniorityDemand = useInsights(
    "offered_jobs_by_seniority",
    { year: beginningYear }
  );
  const senioritySupply = useInsights(
    "talent_supply_by_seniority",
    { year: beginningYear }
  );

  const categoryDemand = useInsights(
    "offered_jobs_by_category",
    { year: beginningYear }
  );
  const categorySupply = useInsights(
    "talent_supply_by_category",
    { year: beginningYear }
  );

  const headcountDemand = useInsights(
    "offered_jobs_by_headcounts",
    { year: beginningYear }
  );
  const headcountSupply = useInsights(
    "talent_supply_by_headcounts",
    { year: beginningYear }
  );

  const Chart = ({ data }) => (
    <div style={{ height: 450 }}>
      <ResponsiveAreaBump
        data={data}
        margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
        spacing={8}
        colors={gobColorScheme}
        theme={gobTheme}
        blendMode="lighten"
        startLabel="percentage"
        startLabelTextColor="#fff"
        startLabelPadding={5}
        endLabelPadding={5}
        endLabelTextColor="#fff"
        axisTop={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -36,
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        tooltip={({ serie }) => {
          return (
            <div className="bg-sherpa-blue border-radius p-1 box-shadow">
              <div>
                <p className="w700">
                  <span className="size1 mr-3" style={{ color: serie.color }}>
                    ●
                  </span>
                  {serie.id}
                </p>
              </div>
              {serie.data.map((d) => (
                <div key={d.x}>
                  <strong className="bold">{d.x}: </strong>
                  {d.y} %
                </div>
              ))}
            </div>
          );
        }}
      />
    </div>
  );

  const VisualizeTechsGroup = ({ title, group, children }) => {
    if (!group) return null;

    const dataPerTag = groupBy("label")(group.data);
    const nivoData = keys(dataPerTag).map((k) => ({
      id: k,
      data: dataPerTag[k].map((d) => ({
        x: d.year,
        y: d.percentage,
      })),
    }));
    return (
      <div className="col-md-6" key={group.group}>
        <h3 className="h3 mt1 text-center w700">{title || group.group}</h3>
        <Chart data={nivoData}></Chart>
        {children}
      </div>
    );
  };

  const VisualizeChart = ({ title, data, children, id }) => {
    if (!data) return null;

    const groupedData = groupBy("label")(data);
    const nivoData = keys(groupedData).map((k) => ({
      id: k,
      data: groupedData[k].map((d) => ({
        x: d.year,
        y: d.percentage,
      })),
    }));
    return (
      <div className="col-md-6" key={id}>
        <h3 className="h3 mt1 text-center w700">{title}</h3>
        <Chart data={nivoData}></Chart>
        {children}
      </div>
    );
  };

  const Header = () => {
    return (
      <ReportHeader
        epigraph="Supply v/s demand"
        headline="How jobs and applications are matching?"
        icon="transactions"
      >
        <p className="size1">
          These charts compare how demand (jobs offered, left column) and supply (applications sent, right column)
          are matching in Get on Board, broken down by seniorities, categories and remote modalities. Comparing left and right charts help detect gaps in talent supply and demand.
        </p>
        <div className="mt2 text-center">
          <a href="#remote-modality" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Remote modality</a>
          <a href="#seniority" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Seniority</a>
          <a href="#category" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Category</a>
          <a href="#headcount" className="p2 inline-block gb-btn mr-2 mb-2 bg-graphite">Headcount</a>
        </div>
      </ReportHeader>
    );
  };

  if (
    !remoteModalitySupply || !remoteModalityDemand ||
    !seniorityDemand || !senioritySupply ||
    !categoryDemand || !categorySupply ||
    !headcountDemand || !headcountSupply
  )
    return (
      <div>
        <Header />
        <Loading />
        <InsightsProBanner
        title="Tómale el pulso al mercado"
        copy="Insights Pro te entrega aún más detalles sobre cómo se comporta el mercado del talento tech en LATAM."
        cta="Conoce más de Insights Pro"
        tag="supply-vs-demand"
      />
      </div>
    );

  return (
    <div>
      <Header />

      {/* Supply vs Demand by remote modalities */}
      <div className="mb5" id="remote-modality"></div>
      <h2 className="h2 w900 text-center mt1">Remote modality</h2>
      <div className="row pb3">
        <VisualizeChart
          data={remoteModalityDemand}
          title="Jobs posted"
          id={"job-posted-by-remote-modality"}
        />
        <VisualizeChart
          data={remoteModalitySupply}
          title="Applications sent"
          id={"app-sent-by-remote-modality"}
        />
      </div>

      {/* Supply vs Demand by seniority */}
      <div className="mb5" id="seniority"></div>
      <h2 className="h2 w900 text-center mt1">Seniority</h2>
      <div className="row pb3">
        <VisualizeChart
          data={seniorityDemand}
          title="Jobs posted"
          id={"job-posted-by-seniority"}
        />
        <VisualizeChart
          data={senioritySupply}
          title="Applications sent"
          id={"app-sent-by-seniority"}
        />
      </div>

      {/* Supply vs Demand by category */}
      <div className="mb5" id="category"></div>
      <h2 className="h2 w900 text-center mt1">Category</h2>
      <div className="row pb3">
        <VisualizeChart
          data={categoryDemand}
          title="Jobs posted"
          id={"job-posted-by-category"}
        />
        <VisualizeChart
          data={categorySupply}
          title="Applications sent"
          id={"app-sent-by-category"}
        />
      </div>

      {/* Supply vs Demand by headcount */}
      <div className="mb5" id="headcount"></div>
      <h2 className="h2 w900 text-center mt1">Headcount</h2>
      <div className="row pb3">
        <VisualizeChart
          data={headcountDemand}
          title="Jobs posted"
          id={"job-posted-by-headcount"}
        />
        <VisualizeChart
          data={headcountSupply}
          title="Applications sent"
          id={"app-sent-by-headcount"}
        />
      </div>

    </div>
  );
};

export default SupplyVsDemand;
